import React from 'react';
import Confetti from 'react-confetti';
import {Box, Button, Grid} from "@mui/material";
import endScreenGameStyles from "../../styles/gameKnowYourTribe/endScreenGame";
import tapPhoto from "../../assets/images/know_your_tribe/tap_ecranfinal.png";
import {paths} from "../../AppRouters";
import {NavLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../stores/store";
import {resetCardsView} from "../../features/kozelMeet/gameKnowYourTribeSlice";
import {setGameStatus} from "../../features/games/gameSlice";
import {gameNameApi} from "./FourthScreenTheGame";

interface EndScreenGameProps {
    goToStep: React.Dispatch<React.SetStateAction<number>>,
}

const EndScreenGame: React.FC<EndScreenGameProps> = ({goToStep}) => {
    const styles = endScreenGameStyles();
    const dispatch = useAppDispatch();
    const {isAuthenticated} = useAppSelector(state => state.auth);
    const handlePlayAgain = () => {
        const resetGame = async () => {
            if(isAuthenticated){
                await dispatch(setGameStatus({
                    gameName: gameNameApi,
                    payload: {state: []}
                }))
            }
            localStorage.removeItem(gameNameApi);
        }

        resetGame().then(() => {
            dispatch(resetCardsView([]));
            goToStep(3);
        });

    }
    return (
        <Grid className={styles.backgroundGrid} container alignItems="center" justifyContent="center" spacing={2}>

            <Grid item className={styles.normalText}>
                <h1>FELICITARI,<br/> AI RASPUNS LA<br/> TOATE INTREBARILE</h1>
            </Grid>
            <Grid item>
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight * 1.6}
                    gravity={0.05}
                    recycle={true}
                    numberOfPieces={1000}
                    confettiSource={{
                        x: 0,
                        y: window.innerWidth < 768 ? window.innerHeight * 0.35 : window.innerHeight * 0.60,
                        w: window.innerWidth,
                        h: 50
                    }}
                    initialVelocityY={2}
                    drawShape={(ctx: CanvasRenderingContext2D) => {
                        ctx.globalAlpha = 0.5; // Set transparency
                        ctx.arc(0, 0, 5, 0, 2 * Math.PI);
                        ctx.fill();
                    }}
                />
            </Grid>
            <Grid container item lg={12} alignItems="center" justifyContent="center">
                <Box className={styles.boxImg} component="img" src={tapPhoto} alt="Card" alignItems="center"
                     justifyContent="center"/>
            </Grid>
            <Grid container item xs={12} alignItems="center" justifyContent="center">
                <Button className={styles.buttonPlayAgain} onClick={() => handlePlayAgain()}>JOACĂ DIN NOU</Button>
            </Grid>
            <Grid item className={styles.smallText}>
                <h2>Vino în comunitatea Kozel: devino membru, formează-ți grupul și câștigați puncte pe care să le
                    transformați în premii!</h2>
            </Grid>
            <Grid container item lg={12} alignItems="center" justifyContent="center">
                <NavLink to={paths.register} className={styles.button}>Devin membru</NavLink>
            </Grid>

        </Grid>

    );
};

export default EndScreenGame;
