import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';

const useKnowYourTribeStyles = createUseStyles((theme: Theme) => {
    return {
        logoBox: {
            maxWidth: '360px',
            maxHeight: '400px',
            width: '100%',
            height: 'auto',
            marginTop: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                width: '35%',
            }
        },
        logoGame:{
            width: '100%',
            height: 'auto',
        },
        separator: {
            maxWidth: '1000px',
            maxHeight: '25px',
            width: '100%',
            height: 'auto',
        },
        separartorTopBox: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(10),
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(2),
            }
        },
        separartorBottomBox: {
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(2),
            }
        }

    };
})

export default useKnowYourTribeStyles;
