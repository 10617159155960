import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useScreenGameStyles = createUseStyles((theme: Theme) => {
    return {
        boxImg: {
            maxWidth: '400px',
            maxHeight: '200px',
            marginTop: theme.spacing(-2),
            marginBottom: theme.spacing(-2),
            [theme.breakpoints.down('lg')]: {
                maxWidth: '120px',
            },
        },

        backgroundGrid: {
            color: theme.colors.dark_chocolate,
        },


        normalText: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.dark_chocolate,
            textAlign: 'center',
            maxWidth: '75%',
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(2),
            },
            '& h1': {
                margin: 0,
            }
        },
        smallText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.3vh,1.3vw)',
            color: theme.colors.dark_chocolate,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            maxWidth: '70%',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(2),
            }
        },

        button: {
            fontFamily: theme.fonts.poplar,
            textTransform: 'uppercase',
            fontWeight: 500,
            marginBottom: theme.spacing(3),
            color: theme.palette.primary.main,
            fontSize: 'max(2.5vh,2.5vw)',
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            marginTop: theme.spacing(-3),
            border: `3px solid ${theme.palette.primary.main}`,
            '&:hover': {
                color: theme.palette.primary.light,
            },
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(-5),
            }
        },
        buttonPlayAgain: {
            fontFamily: theme.fonts.poplar,
            textTransform: 'uppercase',
            fontWeight: 500,
            marginBottom: theme.spacing(0),
            color: theme.colors.latte_cream,
            backgroundColor: theme.colors.dark_chocolate,
            fontSize: 'max(2.5vh,2.5vw)',
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            marginTop: theme.spacing(3),
            '&:hover': {
                backgroundColor: theme.colors.latte_cream,
                color: theme.colors.dark_chocolate
            },
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(5),
            }
        }
    };
})

export default useScreenGameStyles;
