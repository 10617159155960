import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import bg_pergament from '../../assets/images/know_your_tribe/bg_pergament.png';

const useThirdScreenGamePresentationStyles = createUseStyles((theme: Theme) => {
    return {
        boxPergament: {
            backgroundImage: `url(${bg_pergament})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            position: 'relative',
            padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
            width: '750px',
            height: '750px',
            [theme.breakpoints.down('lg')]: {
                width: '350px',
                height: '350px',

            },
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        actionInfo: {
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translate(-50%, 0)',
            maxWidth: '50px',
            maxHeight: '50px',
            [theme.breakpoints.down('lg')]: {
                width: '50px',
                height: 'auto',
                transform: 'translate(-50%, -10px)',
            }
        },
        textTooltip: {
            position: 'absolute',
            top: '50px',
            left: '5%',
            right: '-5%',
            width: 'calc(90% - 48px)',
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            background: theme.colors.dark_chocolate,
            color: theme.colors.ivory_beige,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.colors.ivory_beige,
            textAlign: 'center',
            padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(5)} ${theme.spacing(3)}`,
            zIndex: 50,
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '-10px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: `10px solid ${theme.colors.dark_chocolate}`,
                zIndex: 100,
            },

            '&::after': {
                content: '""',
                position: 'absolute',
                top: '-12px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderLeft: '11px solid transparent',
                borderRight: '11px solid transparent',
                borderBottom: `11px solid ${theme.colors.ivory_beige}`,
                zIndex: 99,
            },
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            color: theme.colors.dark_chocolate,
            textAlign: 'center',
            fontWeight: 'bold',
            paddingBottom: theme.spacing(2),
            lineHeight: 1.1,

        },
        subtitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.8vw,1.8vh)',
            color: theme.colors.dark_chocolate,
            textAlign: 'center',
            fontWeight: 'bold',
            paddingBottom: theme.spacing(2),
            lineHeight: 1.1,
        },
        buttonNextStep: {
            fontFamily: theme.fonts.poplar,
            padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
            fontSize: 'max(1.8vh,1.8vw)',
            backgroundColor: theme.colors.dark_chocolate,
            color: theme.colors.ivory_beige,
            letterSpacing: '1px',
            textTransform: 'none',
        },
    };
})

export default useThirdScreenGamePresentationStyles;
