import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiFailResponse} from "../../utils/interfaces/apiTypes";
import {handleAxiosError} from "../../utils/axiosErrorHandler";
import apiInstance from "../../api/api";
import apiEndpoints from "../../utils/api/endpoints";

export interface GameQuestionProps {
    id: number;
    question: string;
}

export interface GameCategoryProps {
    nr_category: number;
    name_category: string;
    name_html_category: string;
    background_card_color: string;
    text_card_color: string;
    questions: GameQuestionProps[];
}

interface GameKnowYourTribeInitialProps {
    loading: boolean;
    dataGame: any;
    questionsViews: number[];
}


const initialState: GameKnowYourTribeInitialProps = {
    loading: false,
    dataGame: null,
    questionsViews: [],
}

export const getDataGame = createAsyncThunk(
    'game-know-your-tribe/getData',
    async (_, thunkAPI) => {
        try {
            const response = await apiInstance.get(apiEndpoints.gameKnowYourTribe, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ApiFailResponse);
        }
    });

const gameKnowYourTribeSlice = createSlice({
    name: "game-know-your-tribe",
    initialState,
    reducers: {
        updateCardsView: (state, action) => {
            const newValues = Array.isArray(action.payload) ? action.payload : [action.payload];

            state.questionsViews = Array.from(new Set([...state.questionsViews, ...newValues]));

        },
        resetCardsView: (state, action) => {
            state.questionsViews = [];
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDataGame.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getDataGame.fulfilled, (state, action: any) => {
                state.loading = false;
                state.dataGame = action.payload.data
            })
            .addCase(getDataGame.rejected, (state, action: any) => {
                state.loading = false;
            })
    }
})

export const {
    updateCardsView,
    resetCardsView,
} = gameKnowYourTribeSlice.actions;
export default gameKnowYourTribeSlice.reducer