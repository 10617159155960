import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import BereaMea from "./pages/BereaMea";
import KozelLocations from "./pages/KozelLocations";
import OurBeers from "./pages/OurBeers";
import OurTradition from "./pages/OurTradition";
import ServingRitual from "./pages/ServingRitual";
import Regulamente from "./pages/Regulamente";
import KozelMeet from "./pages/KozelMeet";
import RegisterPage from "./pages/RegisterPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import RedirectJoinGroupComponent from "./components/RedirectJoinGroupCOmponent";
import MyGroup from "./pages/MyGroup";
import BottleGame from "./components/kozelMeet/BottleGame";
import KozelNcp from "./pages/KozelNcp";
import ChallengeUntold from "./components/ChallengeUntold";
import VoceaRomanieiPage from "./pages/VoceaRomaniei";
import {isAfterSeptember16th, isBeforeNovember15th} from "./utils/dateTimesUtils";
import PromotionProfiOctober from "./components/ncp/PromotionProfiOctober";
import BoardGameAccess from "./pages/BoardGameAccess";
import KozelMeetBoardGameCongrats from "./pages/KozelMeetBoardGameCongrats";
import KozelMeetSelfImplementedLocations from './components/kozelMeet/SelfImplementedLocations';
import KozelMeetSpecialLocations from './components/kozelMeet/SpecialLocations';
import KnowYourTribe from "./pages/KnowYourTribe";

interface AppRoutersProps {
    isAuthenticated: boolean
}

export const paths = {
    home: '/',
    ncp: '/ncp',
    promotii: '/promotii',
    kozelMeet: '/kozel-meeet',
    kozelMeetSelfImplementedLocations: '/kozel-meeet/self-implemented-locations',
    kozelMeetSpecialLocations: '/kozel-meeet/special-locations',
    kozelMeetBoardGameAccess: '/kozel-meeet-tap-oclock',
    kozelMeetBoardGame:{
        game1: '/kozel-meeet/joc1',
        game2: '/kozel-meeet/joc2',
        game3: '/kozel-meeet/joc3'
    },
    aboutKozel: '/despre-kozel',
    ourBeers: '/berile-noastre',
    ourTradition: '/traditia-noastra',
    servingRitual: '/ritualul-de-servire',
    rules: '/regulamente',
    profile: '/profile',
    myGroup: '/grupul-meu',
    register: '/register',
    locations: '/locatii',
    referralCode: '/referral-code',
    gameRollBottle: '/joc-invarte-sticla',
    forgetPassword: '/forget-password',
    untoldAdvertising: '/home',
    untoldChallenge: {
        challenge1: '/untold-challenge-1',
        challenge2: '/untold-challenge-2',
        challenge3: '/untold-challenge-3',
        challengeFinal: '/untold-challenge-final',
    },
    voceaRomaniei: '/vocea-romaniei',
}

const AppRouters: React.FC<AppRoutersProps> = ({isAuthenticated}) => {

    return <Routes>
        <Route path={paths.home} element={<Home/>}/>
        <Route path={paths.ncp} element={<KozelNcp/>}/>
        <Route path={paths.aboutKozel} element={<BereaMea/>}/>
        <Route path={paths.locations} element={<KozelLocations/>}/>
        <Route path={paths.ourBeers} element={<OurBeers/>}/>
        <Route path={paths.ourTradition} element={<OurTradition/>}/>
        <Route path={paths.servingRitual} element={<ServingRitual/>}/>
        <Route path={paths.rules} element={<Regulamente/>}/>
        <Route path={paths.kozelMeet} element={<KozelMeet/>}/>
        <Route path={paths.kozelMeetSelfImplementedLocations} element={<KozelMeetSelfImplementedLocations/>}/>
        <Route path={paths.kozelMeetSpecialLocations} element={<KozelMeetSpecialLocations/>}/>
        <Route path={paths.referralCode} element={<RedirectJoinGroupComponent/>}/>
        <Route path={paths.untoldChallenge.challenge1} element={<ChallengeUntold/>}/>
        <Route path={paths.untoldChallenge.challenge2} element={<ChallengeUntold/>}/>
        <Route path={paths.untoldChallenge.challenge3} element={<ChallengeUntold/>}/>
        <Route path={paths.untoldChallenge.challengeFinal} element={<ChallengeUntold/>}/>
        <Route path={paths.voceaRomaniei} element={<VoceaRomanieiPage/>}/>
        <Route path={paths.promotii} element={<PromotionProfiOctober/>}/>
        <Route path={paths.kozelMeetBoardGameAccess} element={<KnowYourTribe/>}/>
        <Route path={paths.kozelMeetBoardGame.game1} element={<KozelMeetBoardGameCongrats/>}/>
        <Route path={paths.kozelMeetBoardGame.game2} element={<KozelMeetBoardGameCongrats/>}/>
        <Route path={paths.kozelMeetBoardGame.game3} element={<KozelMeetBoardGameCongrats/>}/>
        {isAuthenticated ? (
            <>
                <Route path={paths.profile} element={<Profile/>}/>
                <Route path={paths.myGroup} element={<MyGroup/>}/>
                <Route path={paths.gameRollBottle} element={<BottleGame/>}/>
            </>
        ) : (
            <>
                <Route path={paths.register} element={<RegisterPage/>}/>
                <Route path={paths.forgetPassword} element={<ForgetPasswordPage/>}/>
                <Route path={paths.profile} element={<Navigate to={paths.home} replace/>}/>
            </>
        )}
        <Route path="*" element={<Navigate to={paths.home}/>}/>
    </Routes>;
}

export default AppRouters;