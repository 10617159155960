import React, {useEffect, useRef, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import bg_img_holder from "../../assets/images/know_your_tribe/bg_img_holder.png";
import useFirstScreenUploadPhotoStyles from "../../styles/gameKnowYourTribe/first_screen_upload_photo";
import {BoardGamePayloadProps, insertBoardGame} from "../../features/kozelMeet/boardGameSlice";
import {useAppDispatch, useAppSelector} from "../../stores/store";

interface FirstScreenUploadPhotoProps {
    selectedImage: null | string,
    setSelectedImage: React.Dispatch<React.SetStateAction<string | null>>,
    goToStep: React.Dispatch<React.SetStateAction<number>>,
}

const FirstScreenUploadPhoto: React.FC<FirstScreenUploadPhotoProps> = ({selectedImage, setSelectedImage, goToStep}) => {
    const styles = useFirstScreenUploadPhotoStyles();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const {id, nick_name} = useAppSelector(state => state.profile);
    const {isAuthenticated} = useAppSelector(state => state.auth);
    const {success} = useAppSelector(state => state.kozelMeetBoardGame)
    const [imgFile, setImgFile] = useState<File | null>(null);

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result as string);
            };
            reader.readAsDataURL(file);
            setImgFile(file)
        }
    };
    useEffect(() => {
        if (success) {
            goToStep(1);
        }
    }, [success]);
    const handleSubmit = async () => {
        if (imgFile) {
            const payload: BoardGamePayloadProps = {
                location: null,
                photo: imgFile,
                extra: {},
            }
            if (isAuthenticated) {
                payload.extra = {userId: id, nick_name: nick_name}
            }
            dispatch(insertBoardGame(payload));

        }
    }

    return (
        <Grid container flexDirection={"column"} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Typography className={styles.subtitle}>
                    Încarcă o poză cu berea Kozel și <br/>primești acces la jocul
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={styles.title}>CUNOAȘTE-ȚI TRIBUL!</Typography>
            </Grid>
            <Grid item xs={12} className={styles.boxImg}>
                <img
                    src={selectedImage || bg_img_holder}
                    alt="placeholder"
                    className={styles.imgPlaceholder}
                    onClick={handleImageClick}
                    style={{cursor: 'pointer'}}
                />
                <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{display: 'none'}}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    className={styles.buttonUploadPhoto}
                    disabled={!selectedImage}
                    onClick={() => handleSubmit()}
                >
                    ÎNCARCĂ POZA
                </Button>
            </Grid>
        </Grid>
    );
}

export default FirstScreenUploadPhoto;
