import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import useSecondScreenChoseModeStyles from "../../styles/gameKnowYourTribe/secondScreenChoseMode";
import {useAppSelector} from "../../stores/store";
import {isNull} from "lodash";

interface SecondScreenChoseModeProps {
    selectedImage: null | string,
    goToStep: React.Dispatch<React.SetStateAction<number>>,
}

const SecondScreenChoseMode: React.FC<SecondScreenChoseModeProps> = ({selectedImage, goToStep}) => {
    const styles = useSecondScreenChoseModeStyles();
    const {currentCode, nextCode} = useAppSelector(state => state.kozelMeetBoardGame);
    const showCodeMessage = () => {
        if (currentCode === nextCode) {
            return currentCode;
        }

        if(isNull(nextCode)) {
            return currentCode;
        }

        return `${currentCode} sau ${nextCode}`
    }

    return (
        <Grid container flexDirection={"column"} justifyContent="center" alignItems="center">
            <Grid item xs={12} className={styles.boxImg}>
                <img src={selectedImage || ''} alt={'image uploaded'} className={styles.imgUploaded} />
            </Grid>
            <Grid item xs={12}>
                <Typography className={styles.normalText}>Folosește codul</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item className={styles.boxCodeInLocation}>
                        <Typography className={styles.textCodeInLocation}>{showCodeMessage()}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography className={styles.normalText}>
                pentru a accesa jocurile din locație, <br/>de la standul Kozel
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={styles.smallText}>
                   sau
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button className={styles.buttonPLayOnline} onClick={() => goToStep(2)}>JOACĂ ONLINE</Button>
            </Grid>
        </Grid>
    );
}

export default SecondScreenChoseMode;
