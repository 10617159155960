import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useFirstScreenUploadPhotoStyles = createUseStyles((theme: Theme) => {
    return {
        subtitle: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.dark_chocolate,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2.2vh,2.2vw)',
            color: theme.colors.dark_chocolate,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
        },
        boxImg: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(10),
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(4),
            }
        },
        imgPlaceholder: {
            maxWidth: '730px',
            maxHeight: '400px',
            width: '100%',
            height: 'auto',
        },
        buttonUploadPhoto: {
            fontFamily: theme.fonts.poplar,
            padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
            fontSize: 'max(1.8vh,1.8vw)',
            backgroundColor: theme.colors.dark_chocolate,
            color: theme.colors.latte_cream,
            letterSpacing: '1px',
            textTransform: 'none',
            '&:disabled': {
                cursor: 'not-allowed',
                opacity: 0.6,
            },
            '&:active': {
                backgroundColor: theme.colors.dark_chocolate,
                color: theme.colors.latte_cream,
            },
            '&:hover': {
                color: theme.colors.dark_chocolate,
                backgroundColor: theme.colors.latte_cream,
            }
        },
    };
})

export default useFirstScreenUploadPhotoStyles;
