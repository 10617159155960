import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import bg_category_base from '../../assets/images/know_your_tribe/bg_category_base.png'
import bg_category_surprise from '../../assets/images/know_your_tribe/bg_category_surprise.png'

const useFourthScreenTheGameStyles = createUseStyles((theme: Theme) => {
    return {
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            textTransform: 'uppercase',
            textAlign: 'center',
            color: theme.colors.dark_chocolate,
        },
        subtitle: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: 'max(1.5vw,1.5vh)',
            textAlign: 'center',
            color: theme.colors.dark_chocolate,
            padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        },
        boxCategories: {},
        containerCategories: {},
        itemCategory: {
            backgroundImage: `url(${bg_category_base})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            objectFit: 'cover',
            margin: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '85%',
            aspectRatio: 1,
            [theme.breakpoints.up('lg')]: {
                maxWidth: '75%',
            },
        },
        itemCategorySurprise: {
            extend: 'itemCategory',
            backgroundImage: `url(${bg_category_surprise})`,
            aspectRatio: 2.2,
            width: 'auto',
            cursor: 'pointer',
            [theme.breakpoints.up('lg')]: {
                maxWidth: '85%',
            },
        },
        showCategory: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems:'center'
        },
        textNumber: {
            fontFamily: theme.fonts.poplar,
            fontWeight: 'bold',
            fontSize: 'max(1.8vw,1.8vh)',
            textAlign: "center",
            color: theme.colors.ivory_beige,
        },
        textCategory: {
            fontFamily: theme.fonts.poplar,
            fontWeight: 'bold',
            fontSize: 'max(1.8vw,1.8vh)',
            textAlign: "center",
            color: theme.colors.ivory_beige,
            wordBreak: 'break-word',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            letterSpacing: theme.spacing(1/8),
            '& span:first-child': {
                fontFamily: theme.fonts.apex,
                fontSize: 'max(1vw,1vh)',
                letterSpacing: 'unset',
            },
            '& span:last-child': {
                letterSpacing: theme.spacing(1/8),
            }
        },

        containerWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vw',
            flexDirection: 'column',
            position: 'relative',
            overflow: 'hidden',
            padding: `${theme.spacing(3)} ${theme.spacing(16)}`,
        },

        imageContainer: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'transform 0.6s ease-in-out',
        },

        image: {
            maxWidth: '250px',
            height: 'auto',
            zIndex: 0,
            [theme.breakpoints.down('lg')]: {
                maxWidth: '160px',
            },
        },

        textContainer: {
            position: 'absolute', // Se plasează peste imagine
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 2,
        },

        textPrimary: {
            fontWeight: 'bold',
            fontSize: 'clamp(20px, 5vw, 25px)',
            textAlign: 'center',
            whiteSpace: 'nowrap',
        },

        textSecondary: {
            fontWeight: 'bold',
            fontSize: 'clamp(14px, 3vw, 16px)',
            textAlign: 'center',
            width: '180%',
        },

        flipped: {
            transform: 'rotateY(180deg)',
            transition: 'transform 0.6s ease-in-out',
        },

        textFlipped: {
            transform: 'translate(-50%, -50%) rotateY(180deg)',
        },

        backgroundImage: {
            width: '100%',
            maxWidth: '250px',
            height: 'auto',
            position: 'absolute',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.7), 0 6px 20px rgba(0, 0, 0, 0.19)',
            [theme.breakpoints.down('lg')]: {
                maxWidth: '160px',
            },
        },
        dialogShowCard: {
            "& .MuiDialog-paper": {
                backgroundColor: "transparent",
                boxShadow: "none",
            },
            "& .MuiBackdrop-root": {
                // backgroundColor: "transparent",
            },
            textAlign: 'center',
        },
        fadeHidden: {
            opacity: 0,
            transition: 'opacity 0.5s ease-in-out',
        },
        fadeVisible: {
            opacity: 1,
            transition: 'opacity 0.5s ease-in-out'
        },
        ctaNextCard: {
            margin: `${theme.spacing(0)} auto`,
            fontFamily: theme.fonts.poplar,
            fontWeight: 'bold',
            fontSize: 'max(2vw,2vh)',
            textAlign: "center",
            color: theme.colors.ivory_beige,
            backgroundColor: theme.colors.dark_chocolate,
            padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
            width: '60%',
            transition: 'color 0.3s ease, background-color 0.3s ease',
                '&:hover': {
                color: theme.colors.dark_chocolate,
                backgroundColor: theme.colors.ivory_beige,
            },
        },
        ctaBackToCategory: {
            margin: `${theme.spacing(1)} auto`,
            fontFamily: theme.fonts.poplar,
            fontWeight: 'bold',
            fontSize: 'max(2vw,2vh)',
            textAlign: "center",
            color: theme.colors.ivory_beige,
            padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
            width: '60%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            lineHeight: 1,
        },

    };
})

export default useFourthScreenTheGameStyles;
