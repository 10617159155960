import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, Grid, Typography} from "@mui/material";
import useFourthScreenTheGameStyles from "../../styles/gameKnowYourTribe/fourthScreenTheGame";
import {useAppDispatch, useAppSelector} from "../../stores/store";
import {getProfile} from "../../features/profile/profileSlice";
import card_transparent from "../../assets/images/know_your_tribe/card_transparent.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import clsx from "clsx";
import {
    GameCategoryProps,
    GameQuestionProps,
    getDataGame,
    updateCardsView
} from "../../features/kozelMeet/gameKnowYourTribeSlice";
import {getGameStatus, setGameStatus} from "../../features/games/gameSlice";
import LoadingComponent from "../kozel/LoadingComponent";

export const gameNameApi = 'know-your-tribe';

interface FourthScreenTheGameProps {
    goToStep: React.Dispatch<React.SetStateAction<number>>,
}

interface ShowQuestionProps {
    categories: GameCategoryProps[];
    cardsLeft: number;
    color: string;
    bgColor: string;
    categoryNr: number;
    categoryText: string;
    question: string;
    closeDialog: () => void;
    handleNextCard: (nr_category: number) => void;
}

const calcRotateCard = (n: number): number => {
    return Math.pow(-1, n) * 15 * Math.floor(n / 2);
};

const ShowQuestion: React.FC<ShowQuestionProps> = ({
                                                       categories,
                                                       cardsLeft,
                                                       color,
                                                       bgColor,
                                                       categoryNr,
                                                       categoryText,
                                                       question,
                                                       closeDialog,
                                                       handleNextCard
                                                   }) => {
    const styles = useFourthScreenTheGameStyles();
    const [flipped, setFlipped] = useState<boolean>(false);
    const [showText, setShowText] = useState<boolean>(true);
    const [activateTransitionRotate, setActivateTransitionRotate] = useState<boolean>(false);
    const arrCardLeft = new Array(cardsLeft).fill(0).map((_, i) => i + 1);

    useEffect(() => {
        setTimeout(() => setActivateTransitionRotate(true), 250)
        setTimeout(() => setShowText(false), 300)
        setTimeout(() => setFlipped(true), 1000);
        setTimeout(() => setShowText(true), 1500)
    }, []);

    const categorySelected = categories.find(category => category.nr_category === categoryNr);

    return (
        <>
            <Box className={styles.containerWrapper}>
                <Box
                    className={`${styles.imageContainer} ${flipped ? styles.flipped : ''}`}
                    style={{zIndex: 101, backgroundColor: bgColor}}
                >
                    <Box component="img" src={card_transparent} alt="Card" className={styles.image}
                         style={{
                             filter: categoryNr === 1 ? 'brightness(0) invert(1)' : 'none',
                             boxShadow: categoryNr === 1 ? 'none' : '0 4px 8px rgba(0, 0, 0, 0.7), 0 6px 20px rgba(0, 0, 0, 0.19)',
                         }}/>

                    <Box className={`${styles.textContainer} ${flipped ? styles.textFlipped : ''}`}>
                        {!flipped && <Typography variant="h3"
                                                 className={clsx(styles.textPrimary, showText ? styles.fadeVisible : styles.fadeHidden)}
                                                 style={{color: color}}>
                            {categoryNr}
                        </Typography>}

                        {flipped && <Typography variant="h5"
                                                className={clsx(styles.textSecondary, showText ? styles.fadeVisible : styles.fadeHidden)}
                                                style={{color: color}}>{question}
                        </Typography>}
                        {!flipped && <Typography variant="h5"
                                                 className={clsx(styles.textSecondary, styles.showCategory, showText ? styles.fadeVisible : styles.fadeHidden)}
                                                 style={{color: color}}
                                                 dangerouslySetInnerHTML={{__html: categoryText}}/>
                        }
                    </Box>
                </Box>

                {arrCardLeft.map((value, index) => (
                    <Box key={index} component="img" src={card_transparent} alt="Card Rotate"
                         className={styles.backgroundImage}
                         style={{
                             backgroundColor: bgColor,
                             zIndex: 100 - value,
                             transform: `rotate(${activateTransitionRotate ? calcRotateCard(value) : -1 * (calcRotateCard(value))}deg)`,
                             transition: 'transform 0.5s ease-in-out',
                         }}/>
                ))}
            </Box>

            {categorySelected && categorySelected.questions.length >= 1 &&
                <Button className={styles.ctaNextCard} onClick={() => handleNextCard(categoryNr)}>Următoarea
                    carte</Button>}
            <Button className={styles.ctaBackToCategory} onClick={() => {
                // Verify if there are questions left
                closeDialog();
            }}>
                <ArrowBackIcon/><span>Înapoi la categorii</span>
            </Button>
        </>
    );
};

const FourthScreenTheGame: React.FC<FourthScreenTheGameProps> = ({goToStep}) => {
    const styles = useFourthScreenTheGameStyles();
    const dispatch = useAppDispatch();
    const {isAuthenticated} = useAppSelector(state => state.auth);
    const [showDialogCard, setShowDialogCard] = useState<boolean>(false);
    const {name} = useAppSelector(state => state.myGroup);
    const [configShowCard, setConfigShowCard] = useState<any | null>()
    const {loading, dataGame, questionsViews} = useAppSelector(state => state.gameKnowYourTribe);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getDataGame());
    }, []);

    useEffect(() => {
        const fetchGameStatus = async () => {
            if (isAuthenticated) {
                try {
                    const resultAction = await dispatch(getGameStatus({gameName: gameNameApi})).unwrap(); // Așteaptă să se termine
                    if (resultAction.gameName === gameNameApi
                        && resultAction?.responseData?.data
                    ) {
                        if (resultAction?.responseData?.data.length > 41) {
                            goToStep(4);
                        } else {
                            dispatch(updateCardsView(resultAction?.responseData?.data));
                        }
                    }
                } catch (error) {
                    console.error("Eroare la preluarea statusului jocului:", error);
                }
            } else {
                const storedData = localStorage.getItem(gameNameApi);
                const questionsViews = storedData ? JSON.parse(storedData) : [];
                if (questionsViews.length > 41) {
                    goToStep(4);
                } else {
                    dispatch(updateCardsView(questionsViews));
                }

            }
        };

        fetchGameStatus().then();
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            if (questionsViews.length > 0) {
                dispatch(setGameStatus({
                    gameName: gameNameApi,
                    payload: {state: questionsViews}
                }))
            }
        } else {
            if (questionsViews.length > 0) {
                localStorage.setItem(gameNameApi, JSON.stringify(questionsViews));
            }
        }
    }, [questionsViews]);

    const title = isAuthenticated && name ? name : 'Salut';

    const setQuestionSeen = (idQuestion: number) => {
        if (questionsViews.includes(idQuestion)) {
            return;
        }
        dispatch(updateCardsView(idQuestion))
    }

    const handleCloseDialog = () => {
        if (categories.every((category: GameCategoryProps) => category.questions.length === 0)) {
            goToStep(4);
        }
        setShowDialogCard(false);
    }

    const handleClickCategory = (nr_category: number) => {
        const category = categories.find((item: GameCategoryProps) => item.nr_category === nr_category);
        if (!category) {
            return;
        }
        const questionObj = category.questions[Math.floor(Math.random() * category.questions.length)]
        if (!questionObj) {
            return;
        }
        setConfigShowCard({
            cardsLeft: category.questions.length,
            color: category.text_card_color,
            bgColor: category.background_card_color,
            categoryNr: nr_category,
            categoryText: category.name_html_category,
            question: questionObj.question,
        })
        setShowDialogCard(true);
        setQuestionSeen(questionObj.id);
    }
    const handleClickSurprise = () => {
        const filterEmptyCategories = categories.filter(
            (item: GameCategoryProps) => item.questions.length > 0
        );
        if (filterEmptyCategories.length === 0) {
            return;
        }

        const randCategNr = Math.floor(Math.random() * filterEmptyCategories.length);

        const categoryRand = filterEmptyCategories[randCategNr];

        if (!categoryRand) {
            console.log('test =>', categoryRand);
            // goToStep(4);
            return;
        }
        const questionObj = categoryRand.questions[Math.floor(Math.random() * categoryRand.questions.length)];
        setConfigShowCard({
            cardsLeft: categoryRand.questions.length,
            color: categoryRand.text_card_color,
            bgColor: categoryRand.background_card_color,
            categoryNr: categoryRand.nr_category,
            categoryText: categoryRand.name_html_category,
            question: questionObj.question
        })
        setTimeout(() => setShowDialogCard(true), 350);
        setQuestionSeen(questionObj.id);
    }
    const handleNextCardCategory = (nr_category: number) => {
        setShowDialogCard(false);
        setTimeout(() => {
            const categoryNext: GameCategoryProps = categories.find((item: GameCategoryProps) => item.nr_category === nr_category);
            if (categoryNext.questions.length === 0) {
                return;
            }
            if (categoryNext) {
                const questionObj = categoryNext.questions[Math.floor(Math.random() * categoryNext.questions.length)]
                setConfigShowCard({
                    cardsLeft: categoryNext.questions.length,
                    color: categoryNext.text_card_color,
                    bgColor: categoryNext.background_card_color,
                    categoryNr: nr_category,
                    categoryText: categoryNext.name_html_category,
                    question: questionObj.question
                })
                setQuestionSeen(questionObj.id);
            }
        }, 300);

        setTimeout(() => setShowDialogCard(true), 350);
    }

    if (loading) {
        return <LoadingComponent/>;
    }

    if (!dataGame) {
        return <></>;
    }

    const categories = dataGame.map((item: GameCategoryProps) => ({
        ...item,
        questions: item.questions.filter((question: GameQuestionProps) => !questionsViews.includes(question.id))
    }));

    const hasQuestions = categories.some((category: GameCategoryProps) => category.questions.length > 0);

    return (
        <Grid container direction="column" justifyContent={"center"} alignContent={'center'} alignItems={'center'}>
            <Grid item xs={12}>
                <Typography className={styles.title}>{title},</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={styles.subtitle}>Selectează una dintre categorii și vezi<br/> la ce întrebare
                    sunteți provocat să răspundeți!</Typography>
            </Grid>
            <Grid item xs={12} className={styles.boxCategories}>
                <Grid container className={styles.containerCategories}>
                    {categories.map((item: GameCategoryProps) => (
                        <Grid key={item.nr_category} item xs={4}>
                            <Box className={clsx(styles.itemCategory)}
                                 sx={{
                                     opacity: item.questions.length > 0 ? 1 : 0.5,
                                     cursor: item.questions.length > 0 ? 'pointer' : 'not-allowed',
                                 }}
                                 onClick={() => handleClickCategory(item.nr_category)}>
                                <Typography className={styles.textNumber}>{item.nr_category}</Typography>
                                <Typography className={styles.textCategory}
                                            dangerouslySetInnerHTML={{__html: item.name_html_category}}/>
                            </Box>
                        </Grid>
                    ))}
                    <Grid item xs={8}>
                        <Box className={clsx(styles.itemCategorySurprise)}
                             sx={{
                                 opacity: hasQuestions ? 1 : 0.5,
                                 cursor: hasQuestions ? 'pointer' : 'not-allowed',
                             }}
                             onClick={() => handleClickSurprise()}>
                            <Typography className={styles.textCategory}>SURPRINDE-MĂ</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog onClose={() => handleCloseDialog()} open={showDialogCard} className={styles.dialogShowCard}>
                {configShowCard && <ShowQuestion
                    categories={categories}
                    color={configShowCard.color}
                    bgColor={configShowCard.bgColor}
                    question={configShowCard.question}
                    cardsLeft={configShowCard.cardsLeft}
                    categoryNr={configShowCard.categoryNr}
                    categoryText={configShowCard.categoryText}
                    closeDialog={() => handleCloseDialog()}
                    handleNextCard={(nr_category) => handleNextCardCategory(nr_category)}
                />}
            </Dialog>
        </Grid>
    );
}

export default FourthScreenTheGame;
