import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useSecondScreenChoseModeStyles = createUseStyles((theme: Theme) => {
    return {
        boxImg: {
            maxWidth: '800px',
            maxHeight: '600px',
        },
        imgUploaded: {
            width: '100%'
        },
        normalText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.dark_chocolate,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            }
        },
        smallText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.1vh,1.1vw)',
            color: theme.colors.dark_chocolate,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            }
        },
        buttonPLayOnline: {
            fontFamily: theme.fonts.poplar,
            padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
            fontSize: 'max(1.8vh,1.8vw)',
            backgroundColor: theme.colors.dark_chocolate,
            color: theme.colors.latte_cream,
            letterSpacing: '1px',
            textTransform: 'none',
            '&:disabled': {
                cursor: 'not-allowed',
                opacity: 0.6,
            },
            '&:active': {
                backgroundColor: theme.colors.dark_chocolate,
                color: theme.colors.latte_cream,
            },
            '&:hover': {
                color: theme.colors.dark_chocolate,
                backgroundColor: theme.colors.latte_cream,
            }
        },
        boxCodeInLocation: {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.colors.dark_chocolate,
            padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        },
        textCodeInLocation: {
            fontFamily: theme.fonts.poplar,
            paddingLeft: theme.spacing(1),
            letterSpacing: theme.spacing(1),
            fontWeight: theme.typography.fontWeightLight,
            fontSize: 'max(2vh,2vw)',
            textAlign: 'center',
            color: theme.colors.dark_chocolate,
        },
    };
})

export default useSecondScreenChoseModeStyles;
