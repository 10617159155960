import React, {useEffect, useState} from 'react';
import { Grid } from "@mui/material";
import useKnowYourTribeStyles from "../styles/know_your_tribe";
import logoKnowYourTribe from '../assets/images/know_your_tribe/logo_know_your_tribe.png';
import separator from '../assets/images/know_your_tribe/separator.png';
import FirstScreenUploadPhoto from "../components/gameKnowYourTribe/FirstScreenUploadPhoto";
import SecondScreenChoseMode from "../components/gameKnowYourTribe/SecondScreenChoseMode";
import {getProfile} from "../features/profile/profileSlice";
import {useAppDispatch, useAppSelector} from "../stores/store";
import ThirdScreenGamePresentation from "../components/gameKnowYourTribe/ThirdScreenGamePresentation";
import FourthScreenTheGame from "../components/gameKnowYourTribe/FourthScreenTheGame";
import EndScreenGame from "../components/gameKnowYourTribe/EndScreenGame";

const KnowYourTribe: React.FC = () => {
    const styles = useKnowYourTribeStyles();
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [stepGame, setStepGame] = useState<number>(0);
    const dispatch = useAppDispatch();
    const {isAuthenticated} = useAppSelector(state => state.auth);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getProfile());
        }
    }, [isAuthenticated]);

    const content = () => {
        switch (stepGame) {
            case 0: return <Grid item xs={8}><FirstScreenUploadPhoto
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
                goToStep={setStepGame}
                /></Grid>;
            case 1: return <Grid item xs={8}><SecondScreenChoseMode selectedImage={selectedImage} goToStep={setStepGame}/></Grid>;
            case 2: return <Grid item xs={12}><ThirdScreenGamePresentation goToStep={setStepGame}/></Grid>;
            case 3: return <Grid item xs={12}><FourthScreenTheGame goToStep={setStepGame}/></Grid>;
            case 4: return <Grid item xs={12}><EndScreenGame goToStep={setStepGame}/></Grid>
            default:
                return <FirstScreenUploadPhoto
                    setSelectedImage={setSelectedImage}
                    selectedImage={selectedImage}
                    goToStep={setStepGame}
                />;
        }
    }

    return (
        <Grid container flexDirection={"column"} justifyContent="center" alignItems="center" spacing={2}>
            <Grid item className={styles.logoBox}>
                <img src={logoKnowYourTribe} alt="Logo Know your tribe" className={styles.logoGame} />
            </Grid>
            <Grid item xs={10} className={styles.separartorTopBox}>
                <img src={separator} alt="separator" className={styles.separator} />
            </Grid>
            {content()}
            <Grid item xs={10} className={styles.separartorBottomBox}>
                <img src={separator} alt="separator" className={styles.separator} />
            </Grid>
        </Grid>
    );
};

export default KnowYourTribe;
