const registerSendCode = '/register/send-code';
const register = '/register';
const registerCheckReferral = '/register/check-referral';
const forgetPasswordSendCode = '/forget-password/send-code';
const forgetPasswordReset = '/forget-password/reset';
const login = '/login'
const refresh = '/refresh';
const validateToken = '/validate-token';
const contactStatus ='/contact/status';
const contactGetProfile = '/contact/profile';
const contactUpdateProfile = '/contact/profile';
const contactGetPersonalData = '/contact/personal-data';
const contactUpdatePersonalData='/contact/personal-data';
const contactGroupCreate = '/contact/group/create';
const contactGetGroup = '/contact/group';
const contactJoinGroup = '/contact/group/join';
const contactGetActivity = '/contact/activity';
const contactAddActivity = '/contact/activity';
const contactGetCoins = '/contact/coins';
const ncpInsert = '/ncp/insert';
const gameGetDetails = '/contact/game/details';
const gameGetState = '/contact/game';
const gameSetState = '/contact/game/update';
const gameGetHistory = '/contact/game/all';
const externalCampaign = '/external_campaign/add';
const kozelMeetBoardGame = '/kozel-meet/board-game/add';
const gameKnowYourTribe = '/game-know-your-tribe';


const endpoints = {
    registerSendCode,
    register,
    registerCheckReferral,
    forgetPasswordSendCode,
    forgetPasswordReset,
    login,
    refresh,
    validateToken,
    contactStatus,
    contactGetProfile,
    contactUpdateProfile,
    contactGetPersonalData,
    contactUpdatePersonalData,
    contactGroupCreate,
    contactGetGroup,
    contactJoinGroup,
    contactGetActivity,
    contactAddActivity,
    contactGetCoins,
    ncpInsert,
    gameGetDetails,
    gameGetState,
    gameSetState,
    gameGetHistory,
    externalCampaign,
    kozelMeetBoardGame,
    gameKnowYourTribe,
}
export default endpoints;