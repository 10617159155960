import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import bg_infoPergament from "../../assets/images/know_your_tribe/bg_infoPergament.png";
import useThirdScreenGamePresentationStyles from "../../styles/gameKnowYourTribe/thirdScreenGamePresentation";
import {ClickAwayListener} from '@mui/base/ClickAwayListener';

interface ThirdScreenGamePresentationProps {
    goToStep: React.Dispatch<React.SetStateAction<number>>,
}

const ThirdScreenGamePresentation: React.FC<ThirdScreenGamePresentationProps> = ({goToStep}) => {
    const styles = useThirdScreenGamePresentationStyles();
    const [showTooltipInfo, setShowTooltipInfo] = React.useState<boolean>(false);

    return (
        <ClickAwayListener onClickAway={() => setShowTooltipInfo(false)}>
            <Grid container className={styles.boxPergament}>
                <img src={bg_infoPergament} alt={'info'} className={styles.actionInfo}
                     onClick={() => setShowTooltipInfo(!showTooltipInfo)}/>
                {showTooltipInfo &&
                    <Typography className={styles.textTooltip}>Un joc de intrebari personale care ajuta grupul sa se
                        cunoasca mai bine. Jucatorii selecteaza una din cele 7 categorii. Fiecare categorie contine 6
                        carti cu intrebari diferite. Dupa afisarea intrebarii, dati drumul distractiei!</Typography>}
                <Typography className={styles.title}>JOCURI, BERE, VOIE BUNĂ -<br/>ȚAPĂN ESTE
                    ÎMPREUNA</Typography>
                <Typography className={styles.subtitle}>Provoacă-ți prietenii la un joc<br/> distractiv și
                    aflați lucruri inedite<br/> unii
                    despre ceilalți</Typography>
                <div><Button className={styles.buttonNextStep} onClick={() => goToStep(3)}>START JOC</Button></div>
            </Grid>
        </ClickAwayListener>
    );
}

export default ThirdScreenGamePresentation;
